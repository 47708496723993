<template>
  <div class="size-request-wrapper">
    <div class="prod-config-loader" v-show="showAbsoluteLoader">
      <absolute-loader :showLoader="showAbsoluteLoader"></absolute-loader>
    </div>
    <div v-show="!showAbsoluteLoader" class="size-request-body">
      <div class="size-request-title d-block d-md-none">
        <h4>{{$t('sizeRequest.title')}}</h4>
      </div>
      <div v-if="!showFeedback" class="size-request-form">
        <form @submit.prevent="sendRequest">
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group id="groupFirstname">
                <base-input
                  name="firstname"
                  v-validate="'required'"
                  v-model="form.firstname"
                  type="text"
                  :label="$t('address.FirstName')+'*'"
                  :validations="[
                    {
                      condition: errors.has('firstname'),
                      text: errors.first('firstname')
                    }
                  ]"
                ></base-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group id="groupLastName">
                <base-input
                  name="lastname"
                  v-validate="'required'"
                  :label="$t('address.LastName')+'*'"
                  v-model="form.lastname"
                  type="text"
                  :validations="[
                    {
                      condition: errors.has('lastname'),
                      text: errors.first('lastname')
                    }
                  ]"
                ></base-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group id="groupEmail">
                <base-input
                  name="email"
                  v-validate="'required|email'"
                  v-model="form.email"
                  type="email"
                  :label="$t('address.Email')+'*'"
                  :validations="[
                    {
                      condition: errors.has('email'),
                      text: errors.first('email')
                    }
                  ]"
                ></base-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group>
                <base-input
                name="telephone"
                autocomplete="telephone"
                v-validate="'required|phoneNumber'"
                v-model="form.telephone"
                type="tel"
                :label="$t('address.PhoneNumber')+'*'"
                :validations="[
                  {
                    condition: errors.has('telephone'),
                    text: errors.first('telephone')
                  }
                ]"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" v-if="sizesAvalailable">
              <b-form-group id="groupContactReason">
                <BaseSelect
                  v-model="form.size"
                  name="product-size"
                  v-validate="'required'"
                  @input="onChangeSize($event)"
                  :label="$t('address.size')+'*'"
                  :options="sizesAvalailable"
                  :validations="[
                    {
                      condition: errors.has('product-size'),
                      text: errors.first('product-size')
                    }
                  ]"
                >
                <template #option="opt">
                  <div class="wrapp-option">
                    <span class="option-label">{{opt.label}}</span>
                  </div>
                </template>
                </BaseSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6" class="privacy-box">
              <p class="privacy-policy" v-html="labelLinkPrivacy"></p>
            </b-col>
            <b-col sm="12" md="6" class="cta-submit-box">
              <button type="submit" class="btn btn-primary submit-form w-100">{{ $t('sizeRequest.send-request') }}</button>
            </b-col>
          </b-row>
        </form>
      </div>
      <div v-if="showFeedback" class="size-request-feedback">
        <p>{{$t('sizeRequest.feedback-success')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from 'theme/components/Form/BaseInput'
import BaseSelect from 'theme/components/Form/BaseSelect'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import Logger from '@/services/Logger'
import find from 'lodash/find'
import { pathTranslate } from '@/helpers/routeHelpers'
import { toast } from '@/modules/notifications'
import { EventBus } from '@/helpers'

export default {
  name: 'SizeRequest',
  inject: ['$validator'],
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filterActive: {
      type: Object,
      required: true
    },
    sizesAvalailable: {
      type: Array,
      required: false
    },
    selectedOption: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      showAbsoluteLoader: false,
      showFeedback: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
        size: ''
      }
    }
  },
  mounted () {
    if (this.selectedOption && this.selectedOption.label) {
      this.form.size = this.selectedOption.label
    }
  },
  computed: {
    getColor () {
      const _this = this
      if (this.product.configurableParent && this.product.configurableParent.groupedVariation) {
        let grouped = this.product.configurableParent.groupedVariation
        let selected = grouped.filter(g => g.color === _this.getProductSelected[0].color)
        if (selected && selected.length > 0) return selected[0].color_label
      }
      let colors = find(this.product.configurable_options, { attribute_code: 'color' })
      if (colors && colors.values && colors.values.length > 0) {
        let color = find(colors.values, { value_index: _this.getProductSelected[0].color })
        if (color) return color.label
      }
      return 'not find'
    },
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    getProductSelected () {
      return this.$store.state.product.tmpProdSelected
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      return this.$t('sizeRequest.terms', { linkPrivacy })
    }
  },
  methods: {
    onChangeSize (option) {
      option['value'] = option.value_index
      EventBus.$emit('sizeSelectorPanel:selected', option)
    },
    sendRequest (event) {
      const _this = this
      this.showAbsoluteLoader = true
      let date = new Date().toLocaleDateString()
      this.$validator.validateAll()
        .then((isValid) => {
          if (isValid) {
            let size = _this.form.size
            if (typeof _this.form.size === 'object' && _this.form.size.label) {
              size = _this.form.size.label
            }
            EventBus.$emit('track:productAction', { event: 'request_size_' + size.split(' ')[0], product: { ...this.product } })
            let params = {
              'firstname': _this.form.firstname,
              'lastname': _this.form.lastname,
              'email': _this.form.email,
              'telephone': _this.form.telephone,
              'date': date,
              'sku': _this.getProductSelected[0].length === 0 ? _this.getProductSelected[0].sku : this.getUserOptionSelected(),
              'color': _this.getColor,
              'size': size,
              'store_code': _this.$store.state.ui.storeViewCode
            }
            _this.$store.dispatch('product/sendSizeRequestToMagento', { params, storeViewCode: _this.$store.state.ui.storeViewCode })
              .then(res => {
                if (res.status === 200) {
                  // toast.success(_this.$t('Request successful'))
                  // _this.clearForm()
                  // console.log(this.requestSuccess)
                  this.requestSuccess = true
                  this.$emit('size-request', true)
                  this.showFeedback = true
                } else {
                  Logger.error(res)
                  res.data && res.data.error_messages && toast.error(_this.$t(res.data.error_messages))
                }
              })
              .catch(err => {
                Logger.error(err)
                const data = err.response.data
                if (data && data.message) {
                  toast.error(_this.$t(data.message))
                } else {
                  toast.error(_this.$t('GenericError'))
                }
              })
              .finally(() => {
                _this.$store.commit('ui/hideLoader')
              })
          } else {
            scrollTo({
              className: 'is-invalid'
            })
          }
        })
        .finally(() => {
          this.showAbsoluteLoader = false
        })
    },
    getUserOptionSelected () {
      let filtersVariants = this.$store.state.product.tmpProdSelected
      let variants = this.product.variants
      let setVariant = []
      if (filtersVariants) {
        for (let i in variants) {
          for (let j in filtersVariants) {
            if (variants[i].product.sku === filtersVariants[j].sku) {
              setVariant.push(variants[i])
            }
          }
        }
      }
      let selected = null
      for (let x in setVariant) {
        for (let y in setVariant[x].attributes) {
          if (setVariant[x].attributes[y].value_index === this.selectedOption.value_index) {
            selected = setVariant[x]
          }
        }
      }
      let tmpSelect = this.$store.state.product.tmpProdSelected
      if (selected) return selected.product.sku
      else if (tmpSelect.length === 1 && tmpSelect[0].sku) return tmpSelect[0].sku
      return 'Sku non selezionato'
    }
  },
  destroyed () {
    this.showFeedback = false
  },
  components: {
    BaseInput,
    BaseSelect,
    AbsoluteLoader
  }
}
</script>
