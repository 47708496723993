var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"size-request-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbsoluteLoader),expression:"showAbsoluteLoader"}],staticClass:"prod-config-loader"},[_c('absolute-loader',{attrs:{"showLoader":_vm.showAbsoluteLoader}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAbsoluteLoader),expression:"!showAbsoluteLoader"}],staticClass:"size-request-body"},[_c('div',{staticClass:"size-request-title d-block d-md-none"},[_c('h4',[_vm._v(_vm._s(_vm.$t('sizeRequest.title')))])]),(!_vm.showFeedback)?_c('div',{staticClass:"size-request-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendRequest($event)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"groupFirstname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"firstname","type":"text","label":_vm.$t('address.FirstName')+'*',"validations":[
                  {
                    condition: _vm.errors.has('firstname'),
                    text: _vm.errors.first('firstname')
                  }
                ]},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"groupLastName"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"lastname","label":_vm.$t('address.LastName')+'*',"type":"text","validations":[
                  {
                    condition: _vm.errors.has('lastname'),
                    text: _vm.errors.first('lastname')
                  }
                ]},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"id":"groupEmail"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"email","label":_vm.$t('address.Email')+'*',"validations":[
                  {
                    condition: _vm.errors.has('email'),
                    text: _vm.errors.first('email')
                  }
                ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phoneNumber'),expression:"'required|phoneNumber'"}],attrs:{"name":"telephone","autocomplete":"telephone","type":"tel","label":_vm.$t('address.PhoneNumber')+'*',"validations":[
                {
                  condition: _vm.errors.has('telephone'),
                  text: _vm.errors.first('telephone')
                }
              ]},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})],1)],1),(_vm.sizesAvalailable)?_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"groupContactReason"}},[_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"product-size","label":_vm.$t('address.size')+'*',"options":_vm.sizesAvalailable,"validations":[
                  {
                    condition: _vm.errors.has('product-size'),
                    text: _vm.errors.first('product-size')
                  }
                ]},on:{"input":function($event){return _vm.onChangeSize($event)}},scopedSlots:_vm._u([{key:"option",fn:function(opt){return [_c('div',{staticClass:"wrapp-option"},[_c('span',{staticClass:"option-label"},[_vm._v(_vm._s(opt.label))])])]}}],null,false,2712109090),model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"privacy-box",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"privacy-policy",domProps:{"innerHTML":_vm._s(_vm.labelLinkPrivacy)}})]),_c('b-col',{staticClass:"cta-submit-box",attrs:{"sm":"12","md":"6"}},[_c('button',{staticClass:"btn btn-primary submit-form w-100",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('sizeRequest.send-request')))])])],1)],1)]):_vm._e(),(_vm.showFeedback)?_c('div',{staticClass:"size-request-feedback"},[_c('p',[_vm._v(_vm._s(_vm.$t('sizeRequest.feedback-success')))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }